/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The different states that a job can be in
 */
export enum BatchJobStatus {
  Errored = "Errored",
  Finished = "Finished",
  New = "New",
  Processing = "Processing",
}

/**
 * The different types of supported jobs
 */
export enum BatchJobType {
  AdminApplyTemplate = "AdminApplyTemplate",
  AdminDelete = "AdminDelete",
  AdminUnVerify = "AdminUnVerify",
  AdminVerify = "AdminVerify",
  AdminZipFiles = "AdminZipFiles",
  CreateUsers = "CreateUsers",
}

/**
 * Available company types
 */
export enum CompanyType {
  Architect = "Architect",
  Engineer = "Engineer",
  GeneralContractor = "GeneralContractor",
  Owner = "Owner",
  SpecialtyContractor = "SpecialtyContractor",
}

/**
 * Valid types of Custom Document Types
 */
export enum CustomType {
  Form = "Form",
  UserCreated = "UserCreated",
}

/**
 * The error type in the document section wrapper
 */
export enum DocumentSectionWrapperErrorType {
  AlreadyPublished = "AlreadyPublished",
  AlreadyUnpublished = "AlreadyUnpublished",
  InternalError = "InternalError",
  MissingDocumentSection = "MissingDocumentSection",
  Validation = "Validation",
  VersionConflict = "VersionConflict",
}

/**
 * Available document types
 */
export enum DocumentType {
  ContractDrawing = "ContractDrawing",
  CustomDocument = "CustomDocument",
  GenericDocument = "GenericDocument",
  ShopDrawing = "ShopDrawing",
  Specification = "Specification",
  Submittal = "Submittal",
}

/**
 * Available types of uploading
 */
export enum DocumentUploadType {
  AUTOMATIC = "AUTOMATIC",
  EMAIL = "EMAIL",
  MANUAL = "MANUAL",
}

/**
 * Defined feature flags
 */
export enum FeatureFlag {
  CustomDocTypes = "CustomDocTypes",
  DocumentTypeTemplates = "DocumentTypeTemplates",
  Forms = "Forms",
  RosterVisibility = "RosterVisibility",
  UnpublishedInSearch = "UnpublishedInSearch",
  ZipFiles = "ZipFiles",
}

/**
 * Available custom document field types
 */
export enum FieldType {
  Boolean = "Boolean",
  Date = "Date",
  DrawingSet = "DrawingSet",
  IssueDate = "IssueDate",
  SpecSection = "SpecSection",
  String = "String",
  Subtitle = "Subtitle",
  Title = "Title",
}

/**
 * Available form field default values
 */
export enum FormFieldDefaultType {
  CurrentDate = "CurrentDate",
  ProjectName = "ProjectName",
  StringValue = "StringValue",
  SubmittedDate = "SubmittedDate",
  UserList = "UserList",
  UserName = "UserName",
}

/**
 * Available form field types
 */
export enum FormFieldType {
  Date = "Date",
  Option = "Option",
  Subtitle = "Subtitle",
  TextArea = "TextArea",
  TextInput = "TextInput",
  Title = "Title",
}

/**
 * The operation that the mapping tool should use
 */
export enum MappingOperation {
  IsInInput = "IsInInput",
  IsNotInInput = "IsNotInInput",
}

/**
 * Ascending or descending order
 */
export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Project Status
 */
export enum ProjectStatus {
  Active_Construction = "Active_Construction",
  Archive = "Archive",
  Bidding = "Bidding",
  Complete = "Complete",
  Preconstruction = "Preconstruction",
  Service = "Service",
}

/**
 * Used to construct a relative date that always remains the given duration from now
 */
export enum RelativeDate {
  Last14Days = "Last14Days",
  Last1Year = "Last1Year",
  Last24Hours = "Last24Hours",
  Last2Days = "Last2Days",
  Last30Days = "Last30Days",
  Last3Months = "Last3Months",
  Last6Months = "Last6Months",
  Last7Days = "Last7Days",
  Last9Months = "Last9Months",
  Over1Year = "Over1Year",
}

/**
 * Names of search setting
 */
export enum SearchSettingName {
  AllowUnverified = "AllowUnverified",
}

/**
 * Various alerts/warnings/issues that can appear on a document section.
 */
export enum SectionValidationAlerts {
  MissingRequiredField = "MissingRequiredField",
  VersionWarning = "VersionWarning",
}

/**
 * The sorting method
 */
export enum Sorting {
  Discipline = "Discipline",
  Division = "Division",
  FileName = "FileName",
  GenericType = "GenericType",
  IssueDate = "IssueDate",
  LastModified = "LastModified",
  PublishedAt = "PublishedAt",
  Relevance = "Relevance",
  SectionNumber = "SectionNumber",
  SheetNumber = "SheetNumber",
  SheetTitle = "SheetTitle",
  ShopType = "ShopType",
  SubmittalNumber = "SubmittalNumber",
  SubmittalStatus = "SubmittalStatus",
  SubmittalStatusDate = "SubmittalStatusDate",
  Title = "Title",
  UploadedAt = "UploadedAt",
  Version = "Version",
}

/**
 * The mode of operation that the string case tool should use
 */
export enum StringCaseMode {
  Lower = "Lower",
  Title = "Title",
  Upper = "Upper",
}

/**
 * Supported File Type
 */
export enum SupportedFileType {
  bmp = "bmp",
  csv = "csv",
  doc = "doc",
  docx = "docx",
  dot = "dot",
  gif = "gif",
  jpeg = "jpeg",
  jpg = "jpg",
  pdf = "pdf",
  png = "png",
  pot = "pot",
  ppa = "ppa",
  pps = "pps",
  ppt = "ppt",
  pptx = "pptx",
  txt = "txt",
  xla = "xla",
  xls = "xls",
  xlsx = "xlsx",
  xlt = "xlt",
}

/**
 * Supported Storage Services
 */
export enum SupportedStorageService {
  autodesk = "autodesk",
  box = "box",
  dropbox = "dropbox",
  egnyte = "egnyte",
  gdrive = "gdrive",
  microsoft = "microsoft",
  onedrivebiz = "onedrivebiz",
  plangrid = "plangrid",
  procore = "procore",
  sharepoint = "sharepoint",
  skydrive = "skydrive",
}

/**
 * Supported types of template fields
 */
export enum TemplateFieldType {
  Date = "Date",
  Match = "Match",
  Static = "Static",
  String = "String",
}

/**
 * User roles at Project
 */
export enum UserRoleAtProject {
  Contributor = "Contributor",
  Manager = "Manager",
  User = "User",
}

/**
 * User roles at Company
 */
export enum UserRolesAtCompany {
  Admin = "Admin",
  Member = "Member",
  Owner = "Owner",
}

export interface BoostSpecSectionInput {
  division?: string | null;
  section?: string | null;
  subsection?: string | null;
  boost: number;
}

export interface ContentToolWrapperInput {
  group?: GroupContentToolInput | null;
  stringCleanup?: StringCleanupContentToolInput | null;
  stringCase?: StringCaseContentToolInput | null;
  stringEnds?: StringEndsContentToolInput | null;
  replace?: ReplaceContentToolInput | null;
  regex?: RegexContentToolInput | null;
  mapping?: MappingContentToolInput | null;
}

export interface DateFilter {
  relative?: RelativeDate | null;
  before?: string | null;
  after?: string | null;
}

export interface DisciplineMappingInput {
  discipline: string;
  mapping: string;
}

export interface DocumentSectionEditSpecificationValue {
  specNumber: string;
  name?: string | null;
}

export interface DocumentSectionFilterFoundation {
  projectId: string;
  commonFilters?: (FieldFilter | null)[] | null;
  documentTypeFilters?: DocumentTypeFilter[] | null;
}

export interface DocumentSectionGenericEdit {
  id: string;
  values: DocumentSectionGenericEditValue[];
}

export interface DocumentSectionGenericEditValue {
  fieldId: string;
  stringValue?: string | null;
  dateValue?: any | null;
  specificationValue?: DocumentSectionEditSpecificationValue | null;
}

export interface DocumentTypeFilter {
  documentType: DocumentType;
  customTypeId?: string | null;
  filters?: FieldFilter[] | null;
}

export interface FieldFilter {
  fieldId: string;
  stringValues?: (string | null)[] | null;
  dateValues?: (DateFilter | null)[] | null;
}

export interface FieldInputValidationInput {
  staticStrings?: string[] | null;
  maxLength?: number | null;
  required?: boolean | null;
}

export interface FormFieldInput {
  fieldName: string;
  displayName: string;
  type: FormFieldType;
  valueTemplate?: string | null;
  filterable: boolean;
  validation: FieldInputValidationInput;
  defaultValue?: FormFieldDefaultType | null;
}

export interface FormInput {
  name: string;
  abbreviation: string;
  autoVerify?: boolean | null;
  fields: FormFieldInput[];
}

export interface FreemiumUserInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface GroupContentToolInput {
  context?: string | null;
  filter: ContentToolWrapperInput[];
}

export interface InputCompanyFields {
  name: string;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  type?: CompanyType | null;
  requireMFA?: boolean | null;
}

export interface InputCustomDocumentTypeField {
  id?: string | null;
  name: string;
  type: FieldType;
  filterable: boolean;
  required: boolean;
}

export interface InputProjectFields {
  name?: string | null;
  nickname?: string | null;
  status?: ProjectStatus | null;
  settings?: SettingsInput | null;
  location?: LocationInput | null;
}

export interface InputUploadedDocument {
  name: string;
  path: string;
  type: DocumentType;
  customTypeId?: string | null;
}

export interface InputUserFields {
  firstName?: string | null;
  lastName?: string | null;
  avatarImageLink?: string | null;
  phone?: string | null;
  isActive?: boolean | null;
}

export interface LocationInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
}

export interface MappingContentToolInput {
  context?: string | null;
  inputValues: string[];
  outputValue: string;
  operation: MappingOperation;
}

export interface NewProjectFields {
  name: string;
  nickname?: string | null;
  status?: ProjectStatus | null;
  location?: LocationInput | null;
}

export interface RegexContentToolInput {
  context?: string | null;
  expression: string;
  capture?: number | null;
  noMatchReturnsOriginal?: boolean | null;
  ignoreCase?: boolean | null;
}

export interface ReplaceContentToolInput {
  context?: string | null;
  expression: string;
  replacement: string;
}

export interface SearchSettingsInput {
  settingName: SearchSettingName;
  documentTypes: string[];
}

/**
 * Specifies how the search results should be sorted
 */
export interface SearchSort {
  sorting?: Sorting | null;
  fieldId?: string | null;
  order: Order;
}

export interface SettingsInput {
  disciplineMappings: DisciplineMappingInput[];
  boostSpecSection?: BoostSpecSectionInput[] | null;
  searchSettings: SearchSettingsInput[];
}

export interface StringCaseContentToolInput {
  context?: string | null;
  mode?: StringCaseMode | null;
}

export interface StringCleanupContentToolInput {
  context?: string | null;
  punctuation?: boolean | null;
  whitespace?: boolean | null;
}

export interface StringEndsContentToolInput {
  context?: string | null;
  prepend?: string | null;
  append?: string | null;
}

export interface TemplateFieldBoxInput {
  x: number;
  y: number;
  width: number;
  height: number;
  origin?: TemplateFieldBoxOriginInput | null;
}

export interface TemplateFieldBoxOriginInput {
  relativeTo?: TemplateFieldBoxRelativToInput | null;
}

export interface TemplateFieldBoxRelativToInput {
  textMatching?: string | null;
  withinRegion?: TemplateFieldRelativeRegionInput | null;
}

export interface TemplateFieldFormInput {
  fieldName: string;
}

export interface TemplateFieldInput {
  type: TemplateFieldType;
  fieldId: string;
  box?: TemplateFieldBoxInput | null;
  form?: TemplateFieldFormInput | null;
  dateFormat?: string | null;
  value?: string | null;
  filter?: ContentToolWrapperInput | null;
}

export interface TemplateFieldRelativeRegionInput {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface TemplateInput {
  adHoc: boolean;
  name: string;
  documentType: DocumentType;
  customTypeId?: string | null;
  fields: TemplateFieldInput[];
  pageWidth: number;
  pageHeight: number;
  settings?: TemplateSettingsInput | null;
  priority?: number | null;
  autoVerify?: boolean | null;
}

export interface TemplateSettingsInput {
  removeCoverSheet?: boolean | null;
  minMustMatch?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
